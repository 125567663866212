const NavMenuOptions = [
    {
        title: 'Home',
        link: '/'
    },
    {
        title: 'About Us',
        link: `${window.location.origin}/AboutUs`
    },
    {
        title: 'News',
        link: `${window.location.origin}/NewsAndBlog`
    },
    {
        title: 'Careers',
        link: `${window.location.origin}/careers`
    },
    {
        title: 'Login',
        link: 'https://app.looq.ai/apps/dashboards/sites',
        target: '_blank'
    }
]

export default NavMenuOptions