import React from 'react'
import { useEffect } from 'react';
// Libraries
import { Link } from 'react-router-dom';
import { motion } from "framer-motion";

// Components
import { fadeIn } from '../../Common/GlobalAnimations';


import { Col, Container, Navbar, Row } from 'react-bootstrap'
import { Header, HeaderNav } from './Header';
import { Footer } from './Footer';



// Component
// import SocialIcons from '../../Components/SocialIcon/SocialIcons';
import { Menu } from './Header';


const Careers = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    // useEffect(() => {
    //     window.dataLayer.push({
    //         event: 'About Us'
    //     });
    // }, []);
    return (
        <div className='About-our-team-sec careers-main-sec relative'>
            <Header topSpace={{ md: true }} type="reverse-scroll">
                <HeaderNav theme="inverse" expand="lg" className="px-[35px] py-[0px]  md:pl-0 md:pr-[15px] md:py-[20px] sm:pr-0">
                    <Col className="col-auto col-sm-6 col-lg-2 mr-auto ps-lg-0 sm:!pl-0">
                        <Link aria-label="header logo" className="flex items-center" to="/">
                            <Navbar.Brand className="inline-block p-0 m-0">
                                <img className="default-logo" width="111" height="36" loading="lazy" src='/assets/img/looq-logo.png' data-rjs='/assets/img/looq-logo.png' alt='logo' />
                                <img className="alt-logo" width="111" height="36" loading="lazy" src='/assets/img/looq-logo.png' data-rjs='/assets/img/looq-logo.png' alt='logo' />
                                <img className="mobile-logo" width="111" height="36" loading="lazy" src='/assets/img/looq-logo.png' data-rjs='/assets/img/looq-logo.png' alt='logo' />
                            </Navbar.Brand>
                        </Link>
                    </Col>
                    <Navbar.Toggle className="order-last md:ml-[25px] sm:ml-[17px]">
                        <span className="navbar-toggler-line"></span>
                        <span className="navbar-toggler-line"></span>
                        <span className="navbar-toggler-line"></span>
                        <span className="navbar-toggler-line"></span>
                    </Navbar.Toggle>
                    <Navbar.Collapse className="col-auto justify-end pr-[15px]">
                        <Menu />
                    </Navbar.Collapse>
                    {/* <SearchBar /> */}
                    {/* <div className="md:hidden pl-[17px]">
                        <HamburgerMenu className="" theme="dark">
                            <Col className="flex flex-col justify-center px-[50px] py-[70px] w-[500px] h-[100vh] shadow-[0_0_20px_rgba(0,0,0,0.3)] right-0 text-center bg-white">
                                <ReactCustomScrollbar theme="dark">
                                    <div>
                                        <h1 className="mb-0 font-bold tracking-[-3px] text-darkgray tracking-[-3px] font-serif uppercase">Hello</h1>
                                        <p className="text-lg font-serif uppercase block">Let's be friends.</p>
                                        <div className="my-20 w-full">
                                            <StaticInstagram /> 
                                        </div>
                                        <div className="mb-20 text-center">
                                            <p className="w-[70%] mb-12 text-darkgray leading-[26px] text-lg font-serif mx-auto inline-block">Get latest update for our trusted applications</p>
                                            <Formik
                                                initialValues={{ email: '' }}
                                                validationSchema={Yup.object().shape({ email: Yup.string().email("Invalid email.").required("Field is required."), })}
                                                onSubmit={async (values, actions) => {
                                                    actions.setSubmitting(true)
                                                    const response = await sendEmail(values)
                                                    response.status === "success" && resetForm(actions)
                                                }}
                                            >
                                                {({ isSubmitting, status }) => (
                                                    <div className="relative subscribe-style-05">
                                                        <Form className="relative">
                                                            <Input showErrorMsg={false} type="email" name="email" className="border-[1px] medium-input rounded-[5px] border-solid border-[#dfdfdf]" placeholder="Enter your email address" />
                                                            <button aria-label="Subscribe" type="submit" className={`text-xs leading-[18px] py-[12px] px-[28px] tracking-normal uppercase xs:text-center${isSubmitting ? " loading" : ""}`}><i className="far fa-envelope text-darkgray text-sm leading-none mr-[10px] xs:mr-0"></i>Subscribe</button>
                                                        </Form>
                                                        <AnimatePresence>
                                                            {status && <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className="mt-[25px] top-[115%] left-0 w-full"><MessageBox className="rounded-[4px] text-md py-[10px] px-[22px] z-10" theme="message-box01" variant="success" message="Your message has been sent successfully subscribed to our email list!" /></motion.div>}
                                                        </AnimatePresence>
                                                    </div>
                                                )}
                                            </Formik>
                                        </div>
                                        <div className="mb-[10px] text-center">
                                         <SocialIcons theme="social-icon-style-05" size="sm" iconColor="dark" data={SocialIconsData} /> 
                                        </div>
                                    </div>
                                </ReactCustomScrollbar>
                            </Col>
                        </HamburgerMenu>
                    </div> */}
                </HeaderNav>
            </Header>

            <motion.section className="py-[130px] overflow-hidden lg:py-[90px] md:py-[75px] sm:py-[50px]"  {...fadeIn}>
                <Container>
                    <Row className="items-center justify-center mb-5">

                        <div className="col-lg-6 md:mt-[50px]">
                            <div class="mb-3 col-lg-12">
                                <h2 class="font-size-46 font-Roboto-Condensed font-bold color-E0604C">Innovation is our language,
                                    AI is our Culture</h2>
                            </div>
                            <Col className="mb-28 z-0 lg:mb-12 md:mb-20">
                                <p className='font-Roboto-Condensed font-size-20 color-000000 mb-3'>
                                    At Looq AI, we blend cutting-edge artificial intelligence with innovative thinking to drive the future of technology. We're fostering a space where creativity meets AI to develop next-generation solutions for the digital era.
                                </p>

                            </Col>
                        </div>
                        <Col xl={6} lg={6} md={10}>
                            <img alt="looq-members" src="assets/img/careers/Dom-Shreyas-Career.jpg" style={{ boxShadow: '10px 10px 0px #58BEC9' }} />
                        </Col>
                    </Row>
                </Container>
            </motion.section>

            <section className="py-[100px] overflow-hidden lg:py-[90px] md:py-[75px] sm:py-[50px] career-bolg-sec relative">
                <Container>
                    <div class="row">
                        <div class="col-lg-3 col-md-3 mb-4"></div>
                        <div className='col-lg-3 col-md-3 mb-4'>
                            <div className='card p-4 border-0'>
                                <h2 className='AssemblyTechnician'>Assembly Technician</h2>
                                <h4 className='address-sec'>San Diego, CA</h4>
                                <h5 className='Full-time'>Full-time</h5>
                                <div>
                                    <button className='engneering-btn'>Engineering</button>
                                </div>
                                <div className='flex align-items-center justify-content-between div-border-top-bottom'>
                                    <Link aria-label="footer menu link" to={`${window.location.origin}/assemblyTechnician`} >MORE INFO</Link>
                                    <i className='fa fa-chevron-right text-[17px]'></i>

                                </div>
                            </div>
                        </div>
                        <div class="col-lg-1 col-md-1 mb-4"></div>
                        <div className='col-lg-3 col-md-3 mb-4'>
                            <div className='card p-4 border-0'>
                                <h2 className='AssemblyTechnician'>Open Vacancy</h2>
                                <h4 className='address-sec'>San Diego, CA</h4>
                                <h5 className='Full-time'>Full-time</h5>
                                <div>
                                    <button className='engneering-btn'>Open</button>
                                </div>
                                <div className='flex align-items-center justify-content-between div-border-top-bottom'>
                                    <Link aria-label="footer menu link" to={`${window.location.origin}/openVacancy`} >MORE INFO</Link>
                                    <i className='fa fa-chevron-right text-[17px]'></i>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-2 mb-4"></div>
                        {/* <div className='col-lg-4 col-md-4 mb-4'>
                            <div className='card p-5 border-0'>
                                <h2 className='AssemblyTechnician'>Open Vacancy</h2>
                                <h4 className='address-sec'>San Diego, CA</h4>
                                <h5 className='Full-time'>Full-time</h5>
                                <div>
                                    <button className='engneering-btn'>Engineering</button>
                                </div>
                                <div className='flex align-items-center justify-content-between div-border-top-bottom'>
                                    <a href='#'>More Info</a>
                                    <i className='fa fa-chevron-right text-[17px]'></i>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </Container>
            </section>

            <Footer></Footer>
        </div>
    )
}
export default Careers;