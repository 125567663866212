import React, { memo } from "react";
import { Link } from "react-router-dom";

// Libraries
import { PropTypes } from "prop-types";
import { motion } from "framer-motion";
//import Pagination from "./HelperComponents/Pagination";


// Data
//import { authorData } from "./BlogData";


const blogData = [
    {
        id: 1,
        category: ["MODERN"],
        blogType: "sideImage",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "assets/img/news-blog/news-article-1-homepage.png",
        title: "A Comprehensive Comparison of Photogrammetric and RTK-GPS Methods for General Order Land Surveying",
        content: "Skeptical about new surveying tech? Blake Furby and Reza Akhavian’s peer-reviewed study, published in MDPI's Buildings, shows terrestrial photogrammetry via the Looq Platform excels in topographic mapping, achieving an average error of 0.0583 feet, well within Caltrans’ error tolerance of 0.2 feet, offering precise and cost-effective surveys.",
        author: 3,
        likes: 25,
        comments: 0,
        date: "June 19, 2024",
        link: "https://www.mdpi.com/2075-5309/14/6/1863"

    },
    {
        id: 2,
        category: ["MODERN"],
        blogType: "sideImage",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "assets/img/news-blog/news-article-2-homepage.png",
        title: "Podcast with Dominique Meyer and Todd Danielson of Informed Infrastructure",
        content: "In this discussion, Meyer and Danielson explore how Looq AI is revolutionizing digital twin automation for the built world. Discover how the qCam is shaping the future of asset intelligence by listening to this podcast.",
        author: 3,
        likes: 25,
        comments: 0,
        date: "May 31st, 2024",
        link: "https://informedinfrastructure.com/94594/podcast-with-dr-dominique-meyer-ceo-and-founder-of-looq-ai/"

    },
    {
        id: 3,
        category: ["MODERN"],
        blogType: "sideImage",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "assets/img/news-blog/News-Article-3-Homepage.png",
        title: "Asset Intelligence is a Major Factor in Modernizing the Energy Grid",
        content: "Dr. Meyer highlights the importance of understanding the existing grid assets as a fundamental step in addressing the challenges posed by new energy demands. This involves leveraging AI to gain critical insights into the physical and informational aspects of the grid.",
        author: 3,
        likes: 25,
        comments: 0,
        date: "May 7, 2024",
        link: "https://marketscale.com/industries/energy/asset-intelligence-is-a-major-factor-in-modernizing-the-energy-grid/"

    },
    {
        id: 4,
        category: ["MODERN"],
        blogType: "sideImage",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "assets/img/news-blog/News-article-4-newspage.png",
        title: "A Look at Looq AI's Digital Twins Platform, its Successful $3.75M Funding, and What's Next",
        content: "“Build computer vision and AI technology that can digitize our built world.”",
        author: 3,
        likes: 25,
        comments: 0,
        date: "May 1, 2024",
        link: `https://www.iotevolutionworld.com/iot/articles/459388-look-looq-ais-digital-twins-platform-its-successful.htm`

    },
    {
        id: 5,
        category: ["MODERN"],
        blogType: "sideImage",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "assets/img/news-blog/news-article-5-newspage.png",
        title: "Success Stories: AI, Digital Twin for Utilities",
        content: "Looq AI’s new AI and digital twin platform, utilizing the proprietary Q camera system, enables rapid, survey-grade infrastructure data capture and processing into geo-referenced 3D digital twins, supported by $2.6 million in seed funding, addressing key challenges in the utilities sector.",
        author: 3,
        likes: 25,
        comments: 0,
        date: "April 29, 2024",
        link: "https://connectedworld.com/success-stories-ai-digital-twin-for-utilities/"

    },
    {
        id: 6,
        category: ["MODERN"],
        blogType: "sideImage",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "assets/img/news-blog/news-article-6-newspage.png",
        title: "How AI   is Revolutionizing Reality Capture and Surveying",
        content: "Here, the Surveying Group explores AI’s transformative power in reality capture and surveying, featuring an insightful interview with Dominique Meyer, PhD, founder of Looq AI, in our latest SGN Show episode. Discover more by watching the full interview!",
        author: 3,
        likes: 25,
        comments: 0,
        date: "April 24, 2024",
        // link: ""

    },
    {
        id: 7,
        category: ["CREATIVE"],
        blogType: "sideImage",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "assets/img/news-blog/news-article-7-newspage.png",
        title: "New Tools Accelerate Geospatial Data Collection and Analysis ",
        content: "Engineering.com highlights how AI and ML technologies like Looq AI’s system revolutionize geospatial data collection, offering survey-grade 3D capture and digital twin integration.",
        author: 1,
        likes: 25,
        comments: 0,
        date: "April 23, 2024",
        //link: "https://www.businesswire.com/news/home/20230713635784/en/Black-Veatch%E2%80%99s-IgniteX-Climate-Tech-Accelerator-Program-Announces-2023-Participants"

    },
    {
        id: 8,
        category: ["BUSINESS"],
        blogType: "sideImage",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "assets/img/news-blog/news-article-8-newspage.png",
        title: "Looq AI: Smart Reality Capture",
        content: "Looq AI has launched a reality capture platform combining computer vision, AI, and a proprietary handheld device with multiple cameras and GPS, offering survey-grade accuracy for rapid infrastructure digitization.",
        author: 2,
        likes: 25,
        comments: 0,
        date: "February 28, 2024",
        link: "https://aecmag.com/reality-capture-modelling/looq-ai-smart-reality-capture/"

    },
    {
        id: 9,
        category: ["MODERN"],
        blogType: "sideImage",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "assets/img/news-blog/news-article-9-newspage.png",
        title: "Looq AI Unveils Revolutionary AI Digital Twin Tech",
        content: "Looq AI introduces groundbreaking AI and computer vision technology for rapid, large-scale infrastructure digitization, featuring a novel “Q” camera system that offers survey-grade 3D capture at a cost-effective scale, securing $2.6M in funding from leading VCs.",
        author: 3,
        likes: 25,
        comments: 0,
        date: "March 4, 2024",
        link: "https://www.linkedin.com/pulse/looq-ai-unveils-revolutionary-digital-twin-tech-q28vc/?trackingId=7U7zf2ibS%2B2Yc%2BQLJb%2F7qA%3D%3D"

    },
    {
        id: 10,
        category: ["MODERN"],
        blogType: "sideImage",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "assets/img/news-blog/news-article-10-newspage.png",
        title: "Looq AI launches reality capture platform",
        content: "Looq AI has launched a reality capture platform that uses advanced computer vision, AI, and a proprietary handheld device with multiple cameras and GPS to enable rapid, survey-grade infrastructure digitization for surveyors, engineers, contractors, and asset owners.",
        author: 3,
        likes: 25,
        comments: 0,
        date: "February 28, 2024",
        link: "https://aecmag.com/reality-capture-modelling/looq-ai-launches-reality-capture-platform/"

    }
]

export const authorData = [
    {
        id: 1,
        img: "assets/img/news-blog/Ellipse.png",
        name: "Ariyella David",
        // description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque eos perferendis, odio ex deserunt, provident modi voluptatibus esse cum vero numquam saepe ea ipsum error blanditiis nobis magni architecto quasi",
        // designation: "co-founder"
    },
    {
        id: 2,
        img: "assets/img/news-blog/Ellipse.png",
        name: "Ariyella David",
        // description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque eos perferendis, odio ex deserunt, provident modi voluptatibus esse cum vero numquam saepe ea ipsum error blanditiis nobis magni architecto quasi",
        // designation: "co-founder"
    },
    {
        id: 3,
        img: "assets/img/news-blog/Ellipse.png",
        name: "Ariyella David",
        // description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque eos perferendis, odio ex deserunt, provident modi voluptatibus esse cum vero numquam saepe ea ipsum error blanditiis nobis magni architecto quasi",
        // designation: "co-founder"
    }
]


const NewsAndBlogsData = (props) => {
    return (
        <div>
            {
                props.data.map((item, i) => {
                    return (
                        <motion.div key={i} className={`blog-sideimage`} {...props.animation}>
                            <div className="blog-post-image">
                                <Link aria-label="link" to={`${item.link}`} target="_blank">
                                    <img height="" width="" alt="blogimage" src={item.img} />
                                </Link>

                            </div>
                            <div className="post-details">
                                <span className="blog-post-date"> {item.date} </span>
                                <Link aria-label="link" to={`${item.link}`} target="_blank" className="blg-post-title"> {item.title} </Link>
                                {/* <span className="blg-post-title"> {item.title} </span> */}
                                <p>{item.content}</p>
                                {/* <div className="blog-author">
                                    <img height="" width="" className="blog-author-img" src={authorData.filter(author => author.id === item.author)[0].img} alt="author" />
                                    <span>
                                        <span> Posted By
                                            <span className="blog-author-name"> {authorData.filter(author => author.id === item.author)[0].name} </span>
                                        </span>
                                         <span className="inline-block"> IN
                                            <Link aria-label="link" to={`/blogs/category/${[item.category[0].toString().split(" ").join("").toLowerCase()]}`} className="blog-author-catagory text-darkgray ml-[4px]" >
                                                {item.category[0]}
                                            </Link>
                                        </span> 
                                    </span>
                                </div> */}
                            </div>
                        </motion.div>
                    );
                })
            }
            {/* {props.pagination === true && (
                <div className="flex justify-center mt-[7.5rem] md:mt-20">
                    <Pagination />
                </div>
            )} */}
        </div>
    );
};

NewsAndBlogsData.defaultProps = {
    data: blogData,
    link: "/blog-types/blog-standard-post/"
};

NewsAndBlogsData.propTypes = {
    grid: PropTypes.string,
    link: PropTypes.string,
    data: PropTypes.arrayOf(
        PropTypes.exact({
            id: PropTypes.number,
            category: PropTypes.array,
            tags: PropTypes.array,
            blogType: PropTypes.string,
            img: PropTypes.string,
            title: PropTypes.string,
            content: PropTypes.string,
            author: PropTypes.number,
            likes: PropTypes.number,
            comments: PropTypes.number,
            date: PropTypes.string,
            double_col: PropTypes.bool
        })
    ),
};

export default memo(NewsAndBlogsData);
