
import React from 'react';
import { Col, Container } from 'react-bootstrap';
import { Parallax } from "react-scroll-parallax";
import { motion } from "framer-motion";
import { fadeIn } from '../../Common/GlobalAnimations';
import Buttons from '../Functions/Buttons';
import CustomModal from '../Functions/CustomModal';


const Technology = (props) => {

    return (
        <div style={{ "--base-color": "#ff7a56" }}>
            <div className="py-[160px] md:flex md:items-center overflow-hidden relative lg:pb-[120px] md:py-[95px] sm:py-[80px]">
                <Parallax className="lg-no-parallax bg-cover absolute left-0 w-full h-[100vh] top-0" translateY={[-40, 40]} style={{ backgroundImage: `url(/assets/img/bg-3d.png)` }}></Parallax>
                {/* <div className="absolute h-full w-full opacity-60 top-0 left-0 bg-gradient-to-tr from-[#2f0959] via-[#5c2359] to-[#fd7956]"></div> */}
                <Container>
                    <motion.div className="row justify-center" {...{ ...fadeIn, transition: { delay: 0.4 } }}>
                        <Col lg={12} sm={12} className="relative text-center">
                            <CustomModal.Wrapper
                                modalBtn={
                                    <span className="inline-flex flex-row items-center justify-center">
                                        <Buttons ariaLabel="modal button" type="submit" className="btn-sonar mx-auto border-0 mr-[15px] mb-14" themeColor="#ff7a56" color="#fff" size="lg" title={<i className="icon-control-play text-[26px]" />} />
                                    </span>
                                } >
                                <div className="w-[1020px] max-w-full relative rounded mx-auto">
                                    <div className="fit-video">
                                        <iframe width="100%" height="100%" className="shadow-[0_0_8px_rgba(0,0,0,0.06)]" controls src="https://www.youtube.com/embed/TIyGR-4PrK0?autoplay=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>

                                    </div>
                                </div>
                            </CustomModal.Wrapper>
                            <h2 className="text-white font-semibold mb-12 font-Roboto-Condensed">The Platform</h2>
                            <p className="text-white font-medium font-Roboto-Condensed tracking-[3px]">Looq AI’s solution is an integrated hardware/software platform comprising the Looq Q-Camera, automated cloud processing, and a web-based application for visualization, analysis, collaboration.</p>
                        </Col>
                    </motion.div>
                </Container>
            </div>
        </div>



        // <section className="py-[100px] lg:py-[90px] md:py-[75px] sm:py-[50px] overflow-hidden startup-processstep the-platfrom-bg-img">
        //     <Container>
        //         <Row className="items-center justify-center">
        //             <Col xl={5} lg={6} md={10}>
        //                 <h1 className="font-Roboto-Condensed font-medium text-white font-size-80 mb-[60px] sm:mb-[10px] line-height">The <br></br>Platform</h1>
        //                 <p className="font-Roboto-Condensed text-white font-size-24 line-height-1-5">
        //                     Looq AI’s solution is an integrated hardware/software platform comprising the Looq Q-Camera, automated cloud processing, and a web-based application for visualization, analysis, collaboration.</p>
        //             </Col>
        //             <Col className="offset-xl-1 col-lg-6 md:mt-[50px]">

        //             </Col>
        //             <motion.div className="offset-xl-1 col-lg-6 md:mt-[50px]" {...fadeInLeft}>
        //             <div className="-mr-[30vw] relative md:mr-0" >
        //                 <img src="/assets/img/technology-image.png" width={786} height={726} className="overflow-hidden" alt="" />
        //             </div>
        //         </motion.div>
        //         </Row>
        //     </Container>
        // </section>
    )

}
export default Technology;