import { Col, Container, Row } from 'react-bootstrap';
import BlogGrid from './BlogGrid';

const blogData = [
    {
        id: 1,
        category: [""],
        blogType: "grid",
        img: "/assets/img/img-3.png",
        title: "A Comprehensive Comparison of Photogrammetric and RTK-GPS Methods for General Order Land Surveying",
        content: "Skeptical about new surveying tech? Blake Furby and Reza Akhavian’s peer-reviewed study, published in MDPI's Buildings, the Looq Platform excels in topographic mapping, achieving an average error of 0.0583 feet, well within Caltrans’ error tolerance of 0.2 feet.",
        link: "https://www.mdpi.com/2075-5309/14/6/1863"
    },
    {
        id: 2,
        category: [""],
        blogType: "grid",
        img: "/assets/img/img-1.png",
        title: "Podcast with Dominique Meyer and Todd Danielson",
        content: "Dr. Meyer highlights the importance of understanding the existing grid assets as a fundamental step in addressing the challenges posed by new energy demands. This involves leveraging AI to gain critical insights into the physical and informational aspects of the grid.",
        link: "https://informedinfrastructure.com/94594/podcast-with-dr-dominique-meyer-ceo-and-founder-of-looq-ai/"
    },
    {
        id: 3,
        category: [""],
        blogType: "grid",
        img: "/assets/img/img-2.png",
        title: "Asset Intelligence is a Major Factor in Modernizing the Energy Grid",
        content: "Dr. Meyer highlights the importance of understanding the existing grid assets as a fundamental step in addressing the challenges posed by new energy demands. This involves leveraging AI to gain critical insights into the physical and informational aspects of the grid.",
        link: "https://marketscale.com/industries/energy/asset-intelligence-is-a-major-factor-in-modernizing-the-energy-grid/"
    },
    // {
    //     id: 4,
    //     category: [""],
    //     blogType: "grid",
    //     img: "/assets/img/dom_sanjayGangal.png",
    //     title: "Sanjay Gangal of AECCafe.com interviews CEO and Co-Founder Dominique Meyer",
    //     content: "Sanjay Gangal interviews CEO and Co-Founder Dominique Meyer to discuss Looq’s objective, benefits and future goals.",
    //     link: "https://aeccafe.com/video/Looq-AI-Dominique-Meyer-CEO/1895680/media.html"
    // },
    // {
    //     id: 5,
    //     category: [""],
    //     blogType: "grid",
    //     img: "/assets/img/kevin.png",
    //     title: "Kevin O’Donovan interviews CEO and Co-Founder Dominique Meyer at DistribuTECH",
    //     content: "Kevin O’Donovan interviews CEO and Co-Founder Dominique Meyer to discuss Looq’s core functionalities and how they can be applied to the power utilities industry.",
    //     link: "https://www.linkedin.com/feed/update/urn:li:activity:7171202708186558465/"
    // },
    // {
    //     id: 6,
    //     category: [""],
    //     blogType: "grid",
    //     img: "/assets/img/destributech.png",
    //     title: "Looq AI Launches AI-Enabled Digital Twin Platform at DistribuTECH 2024",
    //     content: "Looq AI has successfully secured $2.6M in a preferred series seed funding led by BootstrapLabs, a VC firm focused on applied AI. To date, Looq AI has raise a total of $3.75M.",
    //     link: `${window.location.origin}/LOOQ_Letterhead_Press_Release_Launch Looq Platform Final_v1.pdf`
    // }
]

export const PopularArticles = () => {
    return (
        <section className="popularartical-bg pb-[160px] lg:pb-[120px] md:pb-[95px] sm:pb-[80px] xs:pb-[50px]">
            <Container>
                <Row className="justify-center text-center mb-3">
                    <Col>
                        <h2 className="font-bold font-size-46 font-Roboto-Condensed color-E0604C">News</h2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <BlogGrid grid="blog-wrapper grid grid-3col xl-grid-3col lg-grid-3col md-grid-2col sm-grid-2col xs-grid-1col gutter-extra-large" data={blogData} />
                    </Col>
                </Row>
            </Container>
        </section>
    )
}