import React from 'react'
import { useEffect } from 'react';
// Libraries
import { Link } from 'react-router-dom'
import { motion } from "framer-motion"

// Components
import { TiltBox } from './FancyText';
import Team from './Team';
import { fadeIn } from '../../Common/GlobalAnimations';


import { Col, Container, Navbar, Row } from 'react-bootstrap'
import { Header, HeaderNav } from './Header';
import { Footer } from './Footer';



// Component
// import SocialIcons from '../../Components/SocialIcon/SocialIcons';
import { Menu } from './Header';


const TeamData = [
    {
        img: '/assets/img/Team/Dom.png',
        name: 'Dominique Meyer, PhD',
        designation: 'Co-Founder & CEO',
        subtitle: 'Lorem ipsum is simply dummy text of the printing typesetting industry',
        social_links: [
            {
                title: 'facebook',
                link: "https://www.facebook.com",
                icon: "fab fa-facebook-f"
            },
            {
                title: 'dribbble',
                link: "https://www.dribbble.com",
                icon: "fa-brands fa-dribbble"
            },
            {
                title: 'twitter',
                link: "https://www.twitter.com",
                icon: "fa-brands fa-twitter"
            },
            {
                title: 'instagram',
                link: "https://www.instagram.com",
                icon: "fa-brands fa-instagram"
            },
        ]
    },
    {
        img: '/assets/img/Team/Shreyas.png',
        name: 'Shreyas Niradi, MS',
        designation: 'Co-Founder & Chief Science Officer',
        subtitle: 'Lorem ipsum is simply dummy text of the printing typesetting industry',
        social_links: [
            {
                title: 'facebook',
                link: "https://www.facebook.com",
                icon: "fab fa-facebook-f"
            },
            {
                title: 'dribbble',
                link: "https://www.dribbble.com",
                icon: "fa-brands fa-dribbble"
            },
            {
                title: 'twitter',
                link: "https://www.twitter.com",
                icon: "fa-brands fa-twitter"
            },
            {
                title: 'instagram',
                link: "https://www.instagram.com",
                icon: "fa-brands fa-instagram"
            },
        ]
    },
    // {
    //     img: '/assets/img/Team/Blank.png',
    //     name: 'Tom Wypych, PhD',
    //     designation: 'Chief Technology Officer',
    //     subtitle: 'Lorem ipsum is simply dummy text of the printing typesetting industry',
    //     social_links: [
    //         {
    //             title: 'facebook',
    //             link: "https://www.facebook.com",
    //             icon: "fab fa-facebook-f"
    //         },
    //         {
    //             title: 'dribbble',
    //             link: "https://www.dribbble.com",
    //             icon: "fa-brands fa-dribbble"
    //         },
    //         {
    //             title: 'twitter',
    //             link: "https://www.twitter.com",
    //             icon: "fa-brands fa-twitter"
    //         },
    //         {
    //             title: 'instagram',
    //             link: "https://www.instagram.com",
    //             icon: "fa-brands fa-instagram"
    //         },
    //     ]
    // },
    {
        img: '/assets/img/Team/Todd.png',
        name: 'Todd Hylton, PhD',
        designation: 'Vice President, Strategy',
        subtitle: 'Lorem ipsum is simply dummy text of the printing typesetting industry',
        social_links: [
            {
                title: 'facebook',
                link: "https://www.facebook.com",
                icon: "fab fa-facebook-f"
            },
            {
                title: 'dribbble',
                link: "https://www.dribbble.com",
                icon: "fa-brands fa-dribbble"
            },
            {
                title: 'twitter',
                link: "https://www.twitter.com",
                icon: "fa-brands fa-twitter"
            },
            {
                title: 'instagram',
                link: "https://www.instagram.com",
                icon: "fa-brands fa-instagram"
            },
        ]
    },
    {
        img: '/assets/img/Team/Lukas.png',
        name: 'Lukas Fraser, EIT ',
        designation: 'Vice President, Product',
        subtitle: 'Lorem ipsum is simply dummy text of the printing typesetting industry',
        social_links: [
            {
                title: 'facebook',
                link: "https://www.facebook.com",
                icon: "fab fa-facebook-f"
            },
            {
                title: 'dribbble',
                link: "https://www.dribbble.com",
                icon: "fa-brands fa-dribbble"
            },
            {
                title: 'twitter',
                link: "https://www.twitter.com",
                icon: "fa-brands fa-twitter"
            },
            {
                title: 'instagram',
                link: "https://www.instagram.com",
                icon: "fa-brands fa-instagram"
            },
        ]
    },
    {
        img: '/assets/img/Team/Alex.png',
        name: 'Alex Grant',
        designation: 'Director, Hardware',
        subtitle: 'Lorem ipsum is simply dummy text of the printing typesetting industry',
        social_links: [
            {
                title: 'facebook',
                link: "https://www.facebook.com",
                icon: "fab fa-facebook-f"
            },
            {
                title: 'dribbble',
                link: "https://www.dribbble.com",
                icon: "fa-brands fa-dribbble"
            },
            {
                title: 'twitter',
                link: "https://www.twitter.com",
                icon: "fa-brands fa-twitter"
            },
            {
                title: 'instagram',
                link: "https://www.instagram.com",
                icon: "fa-brands fa-instagram"
            },
        ]
    },
    {
        img: '/assets/img/Team/Johnathan.png',
        name: 'Jonathan Klingspon',
        designation: 'Systems Engineer',
        subtitle: 'Lorem ipsum is simply dummy text of the printing typesetting industry',
        social_links: [
            {
                title: 'facebook',
                link: "https://www.facebook.com",
                icon: "fab fa-facebook-f"
            },
            {
                title: 'dribbble',
                link: "https://www.dribbble.com",
                icon: "fa-brands fa-dribbble"
            },
            {
                title: 'twitter',
                link: "https://www.twitter.com",
                icon: "fa-brands fa-twitter"
            },
            {
                title: 'instagram',
                link: "https://www.instagram.com",
                icon: "fa-brands fa-instagram"
            },
        ]
    },
    {
        img: '/assets/img/Team/Filip.png',
        name: 'Filip Piękniewski, PhD ',
        designation: 'Director, Software',
        subtitle: 'Lorem ipsum is simply dummy text of the printing typesetting industry',
        social_links: [
            {
                title: 'facebook',
                link: "https://www.facebook.com",
                icon: "fab fa-facebook-f"
            },
            {
                title: 'dribbble',
                link: "https://www.dribbble.com",
                icon: "fa-brands fa-dribbble"
            },
            {
                title: 'twitter',
                link: "https://www.twitter.com",
                icon: "fa-brands fa-twitter"
            },
            {
                title: 'instagram',
                link: "https://www.instagram.com",
                icon: "fa-brands fa-instagram"
            },
        ]
    },
    {
        img: '/assets/img/Team/Samuel.png',
        name: 'Samuel Germazion, MBA',
        designation: 'Data Scientist',
        subtitle: 'Lorem ipsum is simply dummy text of the printing typesetting industry',
        social_links: [
            {
                title: 'facebook',
                link: "https://www.facebook.com",
                icon: "fab fa-facebook-f"
            },
            {
                title: 'dribbble',
                link: "https://www.dribbble.com",
                icon: "fa-brands fa-dribbble"
            },
            {
                title: 'twitter',
                link: "https://www.twitter.com",
                icon: "fa-brands fa-twitter"
            },
            {
                title: 'instagram',
                link: "https://www.instagram.com",
                icon: "fa-brands fa-instagram"
            },
        ]
    },
    {
        img: '/assets/img/Team/Maria-Garcia.png',
        name: 'Maria Garcia',
        designation: 'Operations Manager',
        subtitle: 'Lorem ipsum is simply dummy text of the printing typesetting industry',
        social_links: [
            {
                title: 'facebook',
                link: "https://www.facebook.com",
                icon: "fab fa-facebook-f"
            },
            {
                title: 'dribbble',
                link: "https://www.dribbble.com",
                icon: "fa-brands fa-dribbble"
            },
            {
                title: 'twitter',
                link: "https://www.twitter.com",
                icon: "fa-brands fa-twitter"
            },
            {
                title: 'instagram',
                link: "https://www.instagram.com",
                icon: "fa-brands fa-instagram"
            },
        ]
    },
    {
        img: '/assets/img/Team/Cameron-Bailey.png',
        name: 'Cameron Bailey',
        designation: 'Full Stack Software Developer',
        subtitle: 'Lorem ipsum is simply dummy text of the printing typesetting industry',
        social_links: [
            {
                title: 'facebook',
                link: "https://www.facebook.com",
                icon: "fab fa-facebook-f"
            },
            {
                title: 'dribbble',
                link: "https://www.dribbble.com",
                icon: "fa-brands fa-dribbble"
            },
            {
                title: 'twitter',
                link: "https://www.twitter.com",
                icon: "fa-brands fa-twitter"
            },
            {
                title: 'instagram',
                link: "https://www.instagram.com",
                icon: "fa-brands fa-instagram"
            },
        ]
    },
    // {
    //     img: '/assets/img/Team/Blank.png',
    //     name: 'Peter Torma, PhD',
    //     designation: 'Computer Vision Engineer',
    //     subtitle: 'Lorem ipsum is simply dummy text of the printing typesetting industry',
    //     social_links: [
    //         {
    //             title: 'facebook',
    //             link: "https://www.facebook.com",
    //             icon: "fab fa-facebook-f"
    //         },
    //         {
    //             title: 'dribbble',
    //             link: "https://www.dribbble.com",
    //             icon: "fa-brands fa-dribbble"
    //         },
    //         {
    //             title: 'twitter',
    //             link: "https://www.twitter.com",
    //             icon: "fa-brands fa-twitter"
    //         },
    //         {
    //             title: 'instagram',
    //             link: "https://www.instagram.com",
    //             icon: "fa-brands fa-instagram"
    //         },
    //     ]
    // },
    // {
    //     img: '/assets/img/Team/Blank.png',
    //     name: 'Gabor Pelesz, MS',
    //     designation: 'Computer Vision Engineer',
    //     subtitle: 'Lorem ipsum is simply dummy text of the printing typesetting industry',
    //     social_links: [
    //         {
    //             title: 'facebook',
    //             link: "https://www.facebook.com",
    //             icon: "fab fa-facebook-f"
    //         },
    //         {
    //             title: 'dribbble',
    //             link: "https://www.dribbble.com",
    //             icon: "fa-brands fa-dribbble"
    //         },
    //         {
    //             title: 'twitter',
    //             link: "https://www.twitter.com",
    //             icon: "fa-brands fa-twitter"
    //         },
    //         {
    //             title: 'instagram',
    //             link: "https://www.instagram.com",
    //             icon: "fa-brands fa-instagram"
    //         },
    //     ]
    // },


]

const AboutUs = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        window.dataLayer.push({
            event: 'About Us'
        });
    }, []);
    return (
        <div className='About-our-team-sec'>
            <Header topSpace={{ md: true }} type="reverse-scroll">
                <HeaderNav theme="inverse" expand="lg" className="px-[35px] py-[0px]  md:pl-0 md:pr-[15px] md:py-[20px] sm:pr-0">
                    <Col className="col-auto col-sm-6 col-lg-2 mr-auto ps-lg-0 sm:!pl-0">
                        <Link aria-label="header logo" className="flex items-center" to="/">
                            <Navbar.Brand className="inline-block p-0 m-0">
                                <img className="default-logo" width="111" height="36" loading="lazy" src='/assets/img/looq-logo.png' data-rjs='/assets/img/looq-logo.png' alt='logo' />
                                <img className="alt-logo" width="111" height="36" loading="lazy" src='/assets/img/looq-logo.png' data-rjs='/assets/img/looq-logo.png' alt='logo' />
                                <img className="mobile-logo" width="111" height="36" loading="lazy" src='/assets/img/looq-logo.png' data-rjs='/assets/img/looq-logo.png' alt='logo' />
                            </Navbar.Brand>
                        </Link>
                    </Col>
                    <Navbar.Toggle className="order-last md:ml-[25px] sm:ml-[17px]">
                        <span className="navbar-toggler-line"></span>
                        <span className="navbar-toggler-line"></span>
                        <span className="navbar-toggler-line"></span>
                        <span className="navbar-toggler-line"></span>
                    </Navbar.Toggle>
                    <Navbar.Collapse className="col-auto justify-end pr-[15px]">
                        <Menu />
                    </Navbar.Collapse>
                    {/* <SearchBar /> */}
                    {/* <div className="md:hidden pl-[17px]">
                        <HamburgerMenu className="" theme="dark">
                            <Col className="flex flex-col justify-center px-[50px] py-[70px] w-[500px] h-[100vh] shadow-[0_0_20px_rgba(0,0,0,0.3)] right-0 text-center bg-white">
                                <ReactCustomScrollbar theme="dark">
                                    <div>
                                        <h1 className="mb-0 font-bold tracking-[-3px] text-darkgray tracking-[-3px] font-serif uppercase">Hello</h1>
                                        <p className="text-lg font-serif uppercase block">Let's be friends.</p>
                                        <div className="my-20 w-full">
                                            <StaticInstagram /> 
                                        </div>
                                        <div className="mb-20 text-center">
                                            <p className="w-[70%] mb-12 text-darkgray leading-[26px] text-lg font-serif mx-auto inline-block">Get latest update for our trusted applications</p>
                                            <Formik
                                                initialValues={{ email: '' }}
                                                validationSchema={Yup.object().shape({ email: Yup.string().email("Invalid email.").required("Field is required."), })}
                                                onSubmit={async (values, actions) => {
                                                    actions.setSubmitting(true)
                                                    const response = await sendEmail(values)
                                                    response.status === "success" && resetForm(actions)
                                                }}
                                            >
                                                {({ isSubmitting, status }) => (
                                                    <div className="relative subscribe-style-05">
                                                        <Form className="relative">
                                                            <Input showErrorMsg={false} type="email" name="email" className="border-[1px] medium-input rounded-[5px] border-solid border-[#dfdfdf]" placeholder="Enter your email address" />
                                                            <button aria-label="Subscribe" type="submit" className={`text-xs leading-[18px] py-[12px] px-[28px] tracking-normal uppercase xs:text-center${isSubmitting ? " loading" : ""}`}><i className="far fa-envelope text-darkgray text-sm leading-none mr-[10px] xs:mr-0"></i>Subscribe</button>
                                                        </Form>
                                                        <AnimatePresence>
                                                            {status && <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className="mt-[25px] top-[115%] left-0 w-full"><MessageBox className="rounded-[4px] text-md py-[10px] px-[22px] z-10" theme="message-box01" variant="success" message="Your message has been sent successfully subscribed to our email list!" /></motion.div>}
                                                        </AnimatePresence>
                                                    </div>
                                                )}
                                            </Formik>
                                        </div>
                                        <div className="mb-[10px] text-center">
                                         <SocialIcons theme="social-icon-style-05" size="sm" iconColor="dark" data={SocialIconsData} /> 
                                        </div>
                                    </div>
                                </ReactCustomScrollbar>
                            </Col>
                        </HamburgerMenu>
                    </div> */}
                </HeaderNav>
            </Header>
            <motion.section className="overflow-hidden pt-[130px]"  {...fadeIn}>
                <Container>
                    <div class="justify-center row">
                        <div class="mb-5 text-center col-lg-12">
                            <img src='assets/img/Team/Founders.jpeg' className='img-fluid w-100' alt="Founders"></img>
                        </div>
                    </div>
                    <div class="justify-center row">
                        <div class="mb-3 text-center col-lg-12">
                            <h2 class="font-size-46 font-Roboto-Condensed font-bold color-E0604C">Meet the Founders!</h2>
                        </div>
                    </div>
                    <Row>
                        <Col className="mb-28 z-0 lg:mb-12 md:mb-20">
                            <p className='font-Roboto-Condensed font-size-20 color-000000 mb-3'>
                                At two o'clock in the morning, while sitting on the floor of my garage and constructing our first prototype, my long-time colleague and research partner, Shreyas, and I founded Looq. We had a simple goal: to build computer vision and AI technology that would digitize our built world. We knew that by intersecting camera and software design, we could create what would become one of the most foundational technological innovations needed to overcome global-scale infrastructure challenges. Specifically, we care about making asset data accessible to people—data that would bring transparency to projects, efficiency across organizations, and safety to our society. Today, we are proud to have united a team of uniquely talented technological innovators, and most importantly, visionaries who are set out to change the world.
                            </p>
                            <img src='/assets/img/Team/Dom-signature.png' alt="Dom Signature" />
                            <p className='font-Roboto-Condensed font-size-20 color-000000'>
                                Dominique E. Meyer, PhD <br></br>
                                Co-founder & CEO
                            </p>
                        </Col>
                        {/* <Link to="downsection" offset={0} delay={0} spy={true} smooth={true} duration={800} className="absolute justify-center bottom-[50px] left-1/2 w-[45px] px-0 flex h-[45px] -translate-x-1/2 sm:bottom-7 box-shadow-small rounded-full cursor-pointer">
                            <i className="feather-arrow-down text-lg leading-[1] text-[#e05fc4] bg-white xs:p-[10px] rounded-full flex justify-center items-center"></i>
                        </Link> */}
                    </Row>
                </Container>
            </motion.section>

            <motion.section className="overflow-hidden pt-[100px] pb-[100px] lg:py-[90px] md:py-[70px] sm:py-[50px] relative bg-cover bg-center bg-no-repeat bg-fixed lg:bg-local"  {...fadeIn}>
                <Container fluid className="text-center">
                    <Row>
                        <Col className="text-center mb-28 z-0 lg:mb-12 md:mb-20">
                            <TiltBox>
                                <span className="font-Roboto-Condensed title cover-background font-bold text-center text-[230px] leading-[230px] -tracking-[5px] bg-darkgray lg:text-[170px] lg:leading-[170px] md:text-[130px] md:leading-[130px] sm:text-[100px] sm:leading-[100px] xs:text-[55px] xs:leading-[55px] xs:-tracking-[1px]"
                                    style={{ backgroundImage: `url("/assets/img/news-blog/news-blog-bg.png")` }}>The Team
                                </span>
                            </TiltBox>
                        </Col>
                        {/* <Link to="downsection" offset={0} delay={0} spy={true} smooth={true} duration={800} className="absolute justify-center bottom-[50px] left-1/2 w-[45px] px-0 flex h-[45px] -translate-x-1/2 sm:bottom-7 box-shadow-small rounded-full cursor-pointer">
                            <i className="feather-arrow-down text-lg leading-[1] text-[#e05fc4] bg-white xs:p-[10px] rounded-full flex justify-center items-center"></i>
                        </Link> */}
                    </Row>
                </Container>
            </motion.section>

            <section id="downsection" className="our-team-page-team pb-[100px] lg:pt-[100px] lg:pb-[95px] md:pt-[70px] md:pb-[40px] sm:py-[50px] xs:pb-[25px] switch-tabs">
                <Container>
                    <Team
                        themeColor="light"
                        overlay={["#0039e3cc", "#4132e0cc", "#5e28ddcc", "#741bd9cc", "#8600d4cc"]}
                        theme='team-style-04'
                        data={TeamData}
                        grid="row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4 justify-center"
                        carousel={false}
                        carouselOption={{ slidesPerView: 3, spaceBetween: 30, loop: true, navigation: true, autoplay: { delay: 3000, disableOnInteraction: true }, pagination: { dynamicBullets: true, clickable: true } }} />
                </Container>
            </section>

            <section className="container pb-5">
                <div className="rounded-[5px] overflow-hidden pt-5 md:pt-[72px] sm:pt-24 teams-bg-sec px-5 flex items-center">
                    <Container className='px-5'>
                        <Row className="items-center">
                            <motion.div className="font-medium col-xl-12 col-lg-12 col-md-12 order-lg-2" {...{ ...fadeIn, transition: { delay: 0.5 } }}>
                                <h4 className="-tracking-[.5px] xs:w-[99%] font-size-40 font-Roboto-Condensed Font-bold text-white">Digitizing The Built World</h4>
                                {/* <Link to="position-open" offset={0} delay={0} spy={true} smooth={true} duration={800}>
                                    <Buttons type="submit" ariaLabel="button" className="font-medium font-sixe-16 font-Roboto-Condensed hover:text-white btn-expand" size="lg" color="#fff" themeColor="#232323" title="Call To Action" />
                                </Link> */}
                            </motion.div>
                            {/* <Col xl={{ offset: 1, span: 5 }} lg={{ span: 6, order: 1, offset: 0 }}>
                                <motion.img loading="lazy" src='https://via.placeholder.com/406x506' width="406" height="506" alt="Our Team" className="mx-auto" {...fadeIn} />
                            </Col> */}
                        </Row>
                    </Container>
                </div>
            </section>

            <Footer></Footer>
        </div>
    )
}
export default AboutUs;