import React from 'react'
import { useEffect } from 'react';
// Libraries
import { Link } from 'react-router-dom'

import { Col, Container, Navbar } from 'react-bootstrap'
import { Header, HeaderNav } from './Header';
import { Footer } from './Footer';



// Component
// import SocialIcons from '../../Components/SocialIcon/SocialIcons';
import { Menu } from './Header';


const AssemblyTechnician = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    // useEffect(() => {
    //     window.dataLayer.push({
    //         event: 'About Us'
    //     });
    // }, []);
    return (
        <div className='About-our-team-sec careers-main-sec relative'>
            <Header topSpace={{ md: true }} type="reverse-scroll">
                <HeaderNav theme="inverse" expand="lg" className="px-[35px] py-[0px]  md:pl-0 md:pr-[15px] md:py-[20px] sm:pr-0">
                    <Col className="col-auto col-sm-6 col-lg-2 mr-auto ps-lg-0 sm:!pl-0">
                        <Link aria-label="header logo" className="flex items-center" to="/">
                            <Navbar.Brand className="inline-block p-0 m-0">
                                <img className="default-logo" width="111" height="36" loading="lazy" src='/assets/img/looq-logo.png' data-rjs='/assets/img/looq-logo.png' alt='logo' />
                                <img className="alt-logo" width="111" height="36" loading="lazy" src='/assets/img/looq-logo.png' data-rjs='/assets/img/looq-logo.png' alt='logo' />
                                <img className="mobile-logo" width="111" height="36" loading="lazy" src='/assets/img/looq-logo.png' data-rjs='/assets/img/looq-logo.png' alt='logo' />
                            </Navbar.Brand>
                        </Link>
                    </Col>
                    <Navbar.Toggle className="order-last md:ml-[25px] sm:ml-[17px]">
                        <span className="navbar-toggler-line"></span>
                        <span className="navbar-toggler-line"></span>
                        <span className="navbar-toggler-line"></span>
                        <span className="navbar-toggler-line"></span>
                    </Navbar.Toggle>
                    <Navbar.Collapse className="col-auto justify-end pr-[15px]">
                        <Menu />
                    </Navbar.Collapse>
                    {/* <SearchBar /> */}
                    {/* <div className="md:hidden pl-[17px]">
                        <HamburgerMenu className="" theme="dark">
                            <Col className="flex flex-col justify-center px-[50px] py-[70px] w-[500px] h-[100vh] shadow-[0_0_20px_rgba(0,0,0,0.3)] right-0 text-center bg-white">
                                <ReactCustomScrollbar theme="dark">
                                    <div>
                                        <h1 className="mb-0 font-bold tracking-[-3px] text-darkgray tracking-[-3px] font-serif uppercase">Hello</h1>
                                        <p className="text-lg font-serif uppercase block">Let's be friends.</p>
                                        <div className="my-20 w-full">
                                            <StaticInstagram /> 
                                        </div>
                                        <div className="mb-20 text-center">
                                            <p className="w-[70%] mb-12 text-darkgray leading-[26px] text-lg font-serif mx-auto inline-block">Get latest update for our trusted applications</p>
                                            <Formik
                                                initialValues={{ email: '' }}
                                                validationSchema={Yup.object().shape({ email: Yup.string().email("Invalid email.").required("Field is required."), })}
                                                onSubmit={async (values, actions) => {
                                                    actions.setSubmitting(true)
                                                    const response = await sendEmail(values)
                                                    response.status === "success" && resetForm(actions)
                                                }}
                                            >
                                                {({ isSubmitting, status }) => (
                                                    <div className="relative subscribe-style-05">
                                                        <Form className="relative">
                                                            <Input showErrorMsg={false} type="email" name="email" className="border-[1px] medium-input rounded-[5px] border-solid border-[#dfdfdf]" placeholder="Enter your email address" />
                                                            <button aria-label="Subscribe" type="submit" className={`text-xs leading-[18px] py-[12px] px-[28px] tracking-normal uppercase xs:text-center${isSubmitting ? " loading" : ""}`}><i className="far fa-envelope text-darkgray text-sm leading-none mr-[10px] xs:mr-0"></i>Subscribe</button>
                                                        </Form>
                                                        <AnimatePresence>
                                                            {status && <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className="mt-[25px] top-[115%] left-0 w-full"><MessageBox className="rounded-[4px] text-md py-[10px] px-[22px] z-10" theme="message-box01" variant="success" message="Your message has been sent successfully subscribed to our email list!" /></motion.div>}
                                                        </AnimatePresence>
                                                    </div>
                                                )}
                                            </Formik>
                                        </div>
                                        <div className="mb-[10px] text-center">
                                         <SocialIcons theme="social-icon-style-05" size="sm" iconColor="dark" data={SocialIconsData} /> 
                                        </div>
                                    </div>
                                </ReactCustomScrollbar>
                            </Col>
                        </HamburgerMenu>
                    </div> */}
                </HeaderNav>
            </Header>



            <section className="mt-5 py-[130px] overflow-hidden lg:py-[90px] md:py-[75px] sm:py-[50px]">
                <Container>
                    <div class="row">
                        <div className='col-12'>
                            <h2 class="color-E0604C font-size-36 font-Roboto-Condensed font-bold line-height block -tracking-[.5px] mb-4 w-[85%] lg:mb-[80px] md:mb-12 md:w-[80%] sm:w-full">Assembly Technician</h2>
                            <p className='mb-4'>
                                Established in 2021, Looq AI is not your average tech company. We're on a mission to revolutionize infrastructure digitization and analysis, bringing a fresh perspective to the field. Our innovative survey technology platform, the Looq platform, is changing the game. It enables lightning-fast and scalable 3D capture of survey-grade information, empowering decision-makers and enhancing operational efficiency across engineering and utility projects.
                            </p>

                            <p>
                                <b>Job Description:</b>
                                <br></br>
                                You will be leading the assembly of our electromechanical and optical assemblies for our camera production. You will also oversee our inventory management, and assembly space.
                            </p>
                            <ul className='jobdetails-ul'>
                                <li>
                                    Assemble products, electromechanical and optical sub-assemblies
                                </li>
                                <li>
                                    Performing go/no-go diagnostic tests on sub-assemblies and completed product
                                </li>
                                <li>
                                    Maintain a component inventory
                                </li>
                                <li>
                                    Manage and maintain 3d printers for in house prototyping and small production runs
                                </li>
                            </ul>

                            <p>
                                <b>About you- You’re a master of:</b>
                            </p>
                            <ul className='jobdetails-ul'>
                                <li>
                                    3+ years assembly or equivalent experience in electro-mechanical projects
                                </li>
                                <li>
                                    Excellent written and oral communication skills
                                </li>
                                <li>
                                    Competency in Word, Excel, Powerpoint
                                </li>
                                <li>
                                    Excellent organizational skills and detail-oriented
                                </li>
                            </ul>

                            <p>
                                <b>Bonus points if you can also:</b>
                            </p>
                            <ul className='jobdetails-ul'>
                                <li>
                                    5+ years assembly or equivalent experience in electro-mechanical projects
                                </li>
                                <li>
                                    Bachelor's degree or similar in engineering or equivalent
                                </li>
                                <li>
                                    Experience organizing ordering and receiving orders
                                </li>
                                <li>
                                    Experience integrating and using an inventory management software
                                </li>
                                <li>
                                    Experience setting up assembly lines and quality assurance processes
                                </li>
                            </ul>

                            <p className='mb-4'>
                                <b>Location:</b><br></br>
                                This role is 100% onsite and it's based in sunny Sorrento Valley, San Diego (92126).
                            </p>

                            <p className='mb-4'>
                                <b>Benefits:</b><br></br>
                                We offer great Health, Vision and Dental benefits including unlimited vacation!
                            </p>

                            <p className='mb-4'>
                                <b>Compensation:</b> &nbsp; $18 - $25 per hour
                            </p>

                            <p className='mb-4'>
                                <b>Join Us at Looq AI!</b> At Looq, we celebrate diversity and are proud to be an equal opportunity employer of minorities,
                                women, veterans, and individuals with disabilities. We don't just talk the talk—we walk the walk.
                                If you're ready to make a difference with an innovative team, check out www.looq.ai to learn more.
                                Ready to take the plunge? Send your resume to <b>careers@looq.ai</b>. We look forward to reviewing your application and exploring how you can contribute to our success.
                            </p>

                        </div>

                    </div>
                </Container>
            </section>

            <Footer></Footer>
        </div>
    )
}
export default AssemblyTechnician;  